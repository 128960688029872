import React, { useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import { Stack, Typography, useTheme } from "@mui/material";
import { useFilterContext, useStepContext } from "../../context";
import { steps } from "../Scrollytell/steps";
import {
  getDonutData,
  getMultiselectData,
  getStackedData,
} from "../../Wrangler";
import {
  educationFilters as educationFiltersInitial,
  sexFilters as sexFiltersInitial,
} from "../filters/filters";
import { Box } from "@mui/system";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};

const margin = { top: 10, right: 40, bottom: 40, left: 80 };
const stack = d3.stack().order(d3.stackOrderNone).offset(d3.stackOffsetExpand);

const angleGen = d3
  .pie()
  .value((d) => d)
  .sort((_, i) => i)
  .startAngle(-Math.PI / 2);

const SmartickScroller = ({ data: inputData }) => {
  // console.log("entering with ", data);
  const svgRef = useRef();
  const wrapperRef = useRef();
  const wrapDim = useResizeObserver(wrapperRef);
  const theme = useTheme();
  const { countryFilters, sexFilters, educationFilters } = useFilterContext();
  const { currentStepIndex, previousStepIndex, currentSubitemIndex } =
    useStepContext();

  const [data] = useState(inputData);
  const [filteredData, setFilteredData] = useState(inputData);

  function arcTween(a) {
    var i = d3.interpolate(this._current, a);
    this._current = i(0);
    return (t) => state.arcGen(i(t));
  }

  const memoizedWidth = useMemo(() => {
    return wrapDim ? wrapDim.width : 0;
  }, [wrapDim]);

  const svg = d3.select(svgRef.current);
  const defaultHeight = 700;

  const [state, setState] = useState({
    width: memoizedWidth ? memoizedWidth : 900,
    height: defaultHeight - margin.top - margin.bottom,
    y: d3.scaleLinear().range([defaultHeight - margin.bottom, margin.top]),
    x: d3
      .scaleBand()
      // .domain(data.chart1.data.map((d) => d.country))
      .rangeRound([margin.left, memoizedWidth - margin.right])
      .paddingInner(0.05)
      .paddingOuter(0.1)
      .align(0.2),
    z: d3.scaleOrdinal().range(theme.palette.colors), //.domain(data.chart1.labels),
    arcGen: d3
      .arc()
      .innerRadius(120)
      .outerRadius(190)
      .startAngle((d) => d.startAngle)
      .endAngle((d) => d.endAngle)
      .cornerRadius(12)
      .padAngle(0.01),
    step: 0,
  });

  function stepHandler(
    currentStepIndex,
    previousStepIndex,
    width,
    height,
    filtered
  ) {
    // console.log("entered stepHandler", currentStepIndex, previousStepIndex);

    if (!filtered) return;
    const donutGroup = svg.select(".donutChart");
    const stackedGroup = svg.select(".stackedChart");
    const progressGroup = svg.select(".progressChart");

    if (
      steps[currentStepIndex].type === "donut" &&
      steps[previousStepIndex].type !== "donut"
    ) {
      stackedGroup
        .transition()
        .duration(250)
        .attr("opacity", 0)
        .attr("pointer-events", "none")
        .on("end", () => {
          donutGroup
            .transition()
            .duration(250)
            .attr("opacity", 1)
            .attr("pointer-events", "all");
        });

      progressGroup
        .transition()
        .duration(250)
        .attr("opacity", 0)
        .attr("pointer-events", "none");
    } else if (
      steps[currentStepIndex].type === "stacked" &&
      steps[previousStepIndex].type !== "stacked"
    ) {
      progressGroup
        .transition()
        .duration(250)
        .attr("opacity", 0)
        .attr("pointer-events", "none");

      donutGroup
        .transition()
        .duration(250)
        .attr("opacity", 0)
        .attr("pointer-events", "none")
        .on("end", () => {
          stackedGroup
            .transition()
            .duration(250)
            .attr("opacity", 1)
            .attr("pointer-events", "all");

          // console.log("progress opacitiy", progressGroup.attr("opacity"));
        });
    } else if (steps[currentStepIndex].type === "progress") {
      stackedGroup
        .transition()
        .duration(250)
        .attr("opacity", 0)
        .attr("pointer-events", "none")
        .on("end", () => {
          progressGroup
            .transition()
            .duration(250)
            .attr("opacity", 1)
            .attr("pointer-events", "all");
        });

      donutGroup
        .transition()
        .duration(250)
        .attr("opacity", 0)
        .attr("pointer-events", "none");
    }

    if (steps[currentStepIndex].type === "stacked") {
      const wrangledData = getStackedData(currentStepIndex, filtered);
      // console.log("wrangled data", wrangledData);

      let chartData = steps[currentStepIndex];
      if ([0, 2, 3, 6, 8].includes(currentStepIndex))
        chartData.data = wrangledData;

      renderStackedBar(width, height, chartData);
    } else if (steps[currentStepIndex].type === "donut") {
      //if there are subitems in the step data, render based on subitem index
      if (steps[currentStepIndex].subitems) {
        const wrangledData = getDonutData(currentStepIndex, filtered);
        // console.log("wrangled data", wrangledData);

        let chartData = steps[currentStepIndex];
        if ([1, 4, 5].includes(currentStepIndex)) {
          //foreach subitem, add the wrangled data to the subitem as the responses property
          chartData.subitems.forEach((subitem, i) => {
            subitem.responses = wrangledData[i].responses;
          });
        }

        renderDonut(width, height, {
          ...steps[currentStepIndex],
          responses:
            steps[currentStepIndex].subitems[currentSubitemIndex].responses,
        });
      }
    } else if (steps[currentStepIndex].type === "progress") {
      let chartData = steps[currentStepIndex];

      chartData.multiselect = getMultiselectData(currentStepIndex, filtered);

      renderProgress(width, height, chartData);
    }
  }

  function renderProgress(width, height, stepData) {
    // console.log("rendering piechart with index: ", stepData);
    const progressGroup = svg
      .select(".progressChart")
      .attr("transform", `translate(${-margin.left}, 0)`);
    const contentGroup = progressGroup.select(".content");

    const legendDiv = d3.select(".legendDiv");
    legendDiv.selectAll("*").remove();

    const x = d3
      .scaleLinear()
      .range([margin.left, width - margin.right])
      .domain([0, 1]);

    // console.log("rendering progress with data", x.range());

    const barHeight = 30;
    const spacing = 55;
    const options = Array.from(Object.keys(stepData.multiselect));

    const offset = height / 2 - (options.length * (barHeight + spacing)) / 2;
    contentGroup.attr("transform", `translate(0, ${offset})`);

    contentGroup.selectAll(".bar").remove();

    const bar = contentGroup
      .selectAll(".bar")
      .data(options)
      .join("g")
      .attr("class", "bar")
      .attr(
        "transform",
        (d, i) => `translate(0, ${i * (barHeight + spacing)})`
      );

    bar
      .append("text")
      .attr("x", x(0))
      .attr("y", 0)
      .attr("dy", "0.35em")
      .text((d) => d);

    bar
      .append("rect")
      .attr("x", x(0))
      .attr("y", 18)
      .attr("rx", 4)
      .attr("ry", 4)
      // .attr("fill", d3.schemeTableau10[4]) //"#194E6D"
      .attr("fill", "#399ADE") //"#194E6D"
      .attr("height", barHeight)
      .attr("width", (d) => x(stepData.multiselect[d]));

    bar
      .append("text")
      .attr("x", (d) => x(0) + x(stepData.multiselect[d]) + 8)
      .attr("y", barHeight / 2 + 8)
      .attr("dominant-baseline", "hanging")
      .attr("font-weight", "bold")
      .attr("font-size", "1.3rem")
      .attr("fill", "#399ADE")
      // .attr("dy", "0.35em")
      .text((d) => `${(stepData.multiselect[d] * 100).toFixed(0)}%`);
  }

  function renderStackedBar(width, height, stepData) {
    // console.log("rendering stack", width);
    // console.log("rendo st", height);
    const stackedGroup = svg.select(".stackedChart");
    const stackedContentGroup = stackedGroup.select(".content");

    stackedContentGroup.attr("transform", `translate(0, ${margin.top})`);

    const xScale = state.x.domain(stepData.data.map((d) => d.country));
    const zScale = state.z.domain(stepData.labels).copy();

    if (stepData.colors && stepData.colors.length > 0) {
      zScale.range(stepData.colors);
    }

    var serie = stackedContentGroup
      .selectAll(".serie")
      .data(stack.keys([...stepData.labels].reverse())(stepData.data));
    // .data(stack.keys([...stepData.labels])(stepData.data));

    // console.log("serie", serie);

    serie.exit().remove();
    const serieEnter = serie.enter().append("g").attr("class", "serie");

    serie = serie.merge(serieEnter);
    serie.attr("fill", (d) => zScale(d.key));
    serie.attr("data-key", (d) => d.key);

    serie.selectAll("rect").remove();

    let seriesRect = serie.selectAll("rect").data((d) => d);

    const seriesRectEnter = seriesRect
      .enter()
      .append("rect")
      .classed("serie-rect", true);

    seriesRect = seriesRect.merge(seriesRectEnter);

    seriesRect
      .attr("x", (d) => xScale(d.data.country))
      .attr("y", (d) => state.y(d[1]))
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("opacity", 0)
      .attr("width", xScale.bandwidth())
      .attr("height", (d) =>
        state.y(d[0]) - state.y(d[1]) - 4 < 0
          ? 0
          : state.y(d[0]) - state.y(d[1]) - 4
      )
      .append("title")
      .text(function (d) {
        //get parent of the parent
        const parent = d3.select(this.parentNode.parentNode);
        const key = parent.attr("data-key");
        // console.log(parent, key);
        return `${d.data.country}\n${key}\n${d3.format(".0%")(d.data[key])}`;
      });

    seriesRect
      .transition()
      .duration(1000)
      .delay((_, i) => i * 25)
      .attr("opacity", 1);

    const xAxisSel = stackedContentGroup.selectAll(".axis--x").data([null]);

    const xAxisEnter = xAxisSel
      .enter()
      .append("g")
      .attr("class", "axis axis--x")
      .attr("font-size", "1.2rem");

    xAxisSel
      .merge(xAxisEnter)
      .call(d3.axisBottom(state.x).tickSizeOuter(0))
      .call((g) => g.select(".domain").remove())
      .attr("transform", `translate(${0},${height - margin.bottom})`);

    const yAxisSel = stackedContentGroup.selectAll(".axis--y").data([null]);
    const yAxisEnter = yAxisSel
      .enter()
      .append("g")
      .attr("class", "axis axis--y");

    yAxisSel
      .merge(yAxisEnter)
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(state.y).ticks(3, "%"))
      .call((g) => g.select(".domain").remove());

    const legendDiv = d3.select(".legendDiv");
    legendDiv.selectAll("*").remove();
    const legendDivSel = legendDiv.selectAll("div").data([...stepData.labels]);

    legendDiv.classed("rows", stepData.legend === "rows");

    //append divs and add text
    const entryDiv = legendDivSel
      .enter()
      .append("div")
      .attr("class", "legendEntry");

    entryDiv.classed("rows", stepData.legend === "rows");

    entryDiv
      .append("svg")
      .append("rect")
      .style("width", "16px")
      .style("height", "16px")
      .style("rx", "4px")
      .style("ry", "4px")
      .style("fill", (d) => zScale(d));
    entryDiv.append("div").text((d) => d);
  }

  function renderDonut(width, height, stepData) {
    // console.log("rendering donut", width);
    // console.log("rendering piechart with index: ", stepData);
    const donutGroup = svg.select(".donutChart");
    const donutContentGroup = donutGroup.select(".content");
    const arcsGroup = donutContentGroup.select(".arcs");
    const labels = donutContentGroup.select(".labels");
    const center = { x: width / 2, y: height / 2 };

    //translate the chart to the center of the svg
    donutContentGroup.attr("transform", `translate(${center.x}, ${center.y})`);

    // console.log("angelgen with ", stepData.responses);
    let angles = angleGen(stepData.responses);

    // console.log("angles", angles);
    angles = angles.sort((a, b) => b.index - a.index);

    // console.log("anglres", angles, stepData.responses);

    const arcs = arcsGroup.selectAll("path").data(angles);

    const arcGen = state.arcGen
      .innerRadius((width / 2) * 0.45)
      .outerRadius((width / 2) * 0.75);

    arcs
      .enter()
      .append("path")
      .each(function (d) {
        this._current = d;
      })
      .attr("d", arcGen)
      .attr("fill", (d) => stepData.colors[d.index]);

    donutGroup.selectAll(".valueLabel").remove();
    arcs
      .attr("fill", (d) => stepData.colors[d.index])
      .transition()
      .duration(1000)
      .attrTween("d", arcTween)
      .on("end", () => {
        showLabels();
      });

    const showLabels = () => {
      const valueLabels = labels
        .selectAll(".valueLabel")
        .data(angles)
        .enter()
        .append("text")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .attr("dy", 4)
        .classed("valueLabel", true)
        .attr("opacity", (d) => (d.data < 0.01 ? 0 : 1))
        .attr(
          "transform",
          (d) => `translate(${arcGen.centroid(d)[0]}, ${arcGen.centroid(d)[1]})`
        )
        .text((d) => `${d3.format(".0%")(d.value)}`);

      //clone labels and add a white background
      valueLabels
        .clone(true)
        .style("stroke", "white")
        .style("stroke-width", "4px")
        .lower();
    };

    if (arcs.size() === 0) showLabels(); //if there are no arcs, show the labels immediately

    const legendDiv = d3.select(".legendDiv");
    legendDiv.selectAll("*").remove();

    const legendDivSel = legendDiv.selectAll("div").data([...stepData.labels]);
    legendDiv.classed("rows", stepData.legend === "rows");

    //append divs and add text
    const entryDiv = legendDivSel
      .enter()
      .append("div")
      .attr("class", "legendEntry");

    entryDiv.classed("rows", stepData.legend === "rows");

    entryDiv
      .append("svg")
      .append("rect")
      .style("width", "16px")
      .style("height", "16px")
      .style("rx", "4px")
      .style("ry", "4px")
      .style("fill", (d, i) => stepData.colors[i]);
    entryDiv.append("div").text((d) => d);
  }

  useEffect(() => {
    if (!wrapDim) return;
    const width = wrapDim.width;
    const height = wrapDim.height * 0.9;

    setState({
      ...state,
      x: state.x.rangeRound([margin.left, width - margin.right]),
      y: state.y.range([height - margin.bottom, margin.top]),
      width: width,
      height: height,
    });

    svg.attr("width", width).attr("height", height).attr("overflow", "visible");

    const filteredData = data.filter((d) => {
      // debugger;
      const filteredEducation = (
        educationFilters.length ? educationFilters : educationFiltersInitial
      ).map((c) => c.label);
      const filteredSex = (
        sexFilters.length ? sexFilters : sexFiltersInitial
      ).map((c) => c.value);

      if (countryFilters !== "Global" && countryFilters !== d["País"])
        return false;
      if (!filteredEducation.includes(d["Nivel de estudios:"])) return false;
      if (!filteredSex.includes(d["¿Es usted...?"])) return false;

      return true;
    });

    setFilteredData(filteredData);

    // console.log('filtering for steph: ', countryFilters)
    // console.log('filtered data', filteredData)
    
    stepHandler(
      currentStepIndex,
      previousStepIndex,
      width,
      height,
      filteredData
    );
  }, [wrapDim, countryFilters, educationFilters, sexFilters]);

  useEffect(() => {
    // console.log("Step is ", currentStepIndex, " previous ", previousStepIndex);
    stepHandler(
      currentStepIndex,
      previousStepIndex,
      state.width,
      state.height,
      filteredData
    );
  }, [currentStepIndex, currentSubitemIndex]);

  return (
    <div ref={wrapperRef} className="viz-wrapper">
      <Stack direction={"column"} spacing={3}>
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          pt={"10px"}
          pb={"10px"}
          pl={"18px"}
          pr={"18px"}
          bgcolor={"action.hover"}
          borderRadius={"4px"}
        >
          <Typography
            variant={"subtitle1"}
            fontSize={22}
            fontStyle={"italic"}
            fontWeight={400}
            minHeight={"77px"}
            alignItems={"center"}
            display={"flex"}
            id={"question"}
          >
            {steps[currentStepIndex].title}
          </Typography>
        </Box>
        <svg ref={svgRef}>
          <g className="stackedChart">
            <g className="content"></g>
            <g className="legend" />
          </g>

          <g className="donutChart">
            <g className="content">
              <g className="arcs" />
              <g className="labels" />
            </g>
            <g className="legend" />
          </g>

          <g className="progressChart">
            <g className="content"></g>
          </g>
        </svg>
        <div className="legendDiv"></div>
      </Stack>
    </div>
  );
};

export default SmartickScroller;
