import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#399ADE",
      light: "#77CBFF",
      dark: "#006CAC",
    },
    secondary: {
      main: "#EE9D51",
      light: "#f1b073",
      dark: "#a66d38",
    },
    text: {
      primary: "#363853",
    },
    colors: ["#399ADE", "#FF9D13", "#8A7D8C", "#508054"],
    error: {
      main: "#f44336",
    },
    headerColor: "#194E6D",
  },
  typography: {
    fontFamily: ["Raleway", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Raleway", "sans-serif"].join(","),
      fontWeight: 700,
    },
    h2: {
      fontFamily: ["Raleway", "sans-serif"].join(","),
    },
    h3: {
      fontFamily: ["Raleway", "sans-serif"].join(","),
    },
    h4: {
      fontFamily: ["Raleway", "sans-serif"].join(","),
    },
    h5: {
      fontFamily: ["Raleway", "sans-serif"].join(","),
      fontWeight: 700,
    },
    h6: {
      fontFamily: ["Raleway", "sans-serif"].join(","),
      fontWeight: 700,
      lineHeight: "28px",
    },
  },
});
