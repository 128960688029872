//sequence:
// 1. P2 ¿Cómo de importantes te parecen las matemáticas en la formación de tus hijos/as? (less -> more important) => step 0
// 2. P3+  => 4 subquestions => step 1,2,3,4
// 3. P4 ==> stacked bar charts => step 5
// 4. P5z
// 5. P6 ==> Raul check
// 6. P7 ==> Raul check
// 7. P8 stacked by country less->more
// 8. P9 donut diff colors (categories)
// 9. P10 stacked bar chart categories
import * as d3 from "d3";

export const steps = [
  {
    //P2
    title:
      "¿Cómo de importantes te parecen las matemáticas en la formación de tus hijos/as?",
    description: {
      ES: [
        "El 97% de los padres españoles considera que las matemáticas son fundamentales en la educación de sus hijos.",
        "Existe una correlación positiva entre el nivel de estudios de los padres y la importancia que le otorgan a las matemáticas en la formación de sus hijos.",
        "Cuando las matemáticas eran la asignatura favorita de los padres durante su educación, tienden a darles una mayor importancia en la formación de sus hijos.",
      ],
      CL: [
        "El 97% de los padres chilenos considera que las matemáticas son fundamentales en la educación de sus hijos.",
        "Existe una correlación positiva entre el nivel de estudios de los padres y la importancia que le otorgan a las matemáticas en la formación de sus hijos. ",
        "Cuando las matemáticas eran la asignatura favorita de los padres durante su educación, tienden a darles una mayor importancia en la formación de sus hijos.",
      ],
      MX: [
        "El 96% de los padres mexicanos considera que las matemáticas son fundamentales en la educación de sus hijos.",
        "Existe una correlación positiva entre el nivel de estudios de los padres y la importancia que le otorgan a las matemáticas en la formación de sus hijos.",
        "Cuando las matemáticas eran la asignatura favorita de los padres durante su educación, tienden a darles una mayor importancia en la formación de sus hijos.",
      ],
      CO: [
        "Al 97% de los padres colombianos las matemáticas le parecen muy importantes en la formación de sus hijos.",
        "Cuanto menor es el nivel de estudios de los padres, más importancia le otorgan a las matemáticas en la formación de sus hijos.",
        "Cuando las matemáticas eran la asignatura favorita de los padres durante su educación, tienden a darles una mayor importancia en la formación de sus hijos.",
      ],
      PE: [
        "Al 98% de los padres peruanos las matemáticas le parecen muy importantes en la formación de sus hijos.",
        "Cuanto menor es el nivel de estudios de los padres, más importancia le otorgan a las matemáticas en la formación de sus hijos.",
        "Cuando las matemáticas eran la asignatura favorita de los padres durante su educación, tienden a darles una mayor importancia en la formación de sus hijos.",
      ],
    },
    labels: [
      "Nada Importantes",
      "Poco Importantes",
      "Importantes",
      "Muy Importantes",
      "Extremadamente Importantes",
    ],
    colors: d3.schemePiYG[5],
    type: "stacked",
  },
  {
    //P3
    subitems: [
      {
        description:
          "Las matemáticas son cada vez más esenciales en la vida diaria",
        conclusion: {
          ES: [
            "El 96% de los padres coincide en que las matemáticas son indispensables desenvolverse en la vida diaria.",
          ],
          CL: [
            "El 97% de los padres coincide en que las matemáticas son indispensables desenvolverse en la vida diaria.",
          ],
          MX: [
            "El 96% de los padres coincide en que las matemáticas son indispensables desenvolverse en la vida diaria.",
          ],
          CO: [
            "El 96% de los padres coincide en que las matemáticas son indispensables desenvolverse en la vida diaria.",
          ],
          PE: [
            "El 97% de los padres coincide en que las matemáticas son indispensables desenvolverse en la vida diaria.",
          ],
        },
        // [
        //   "Los padres están de acuerdo en que las matemáticas son tan importantes en la formación de sus hijos por dos razones principalmente: 1.) son esenciales en el día a día (95%) 2.) es un valor añadido para encontrar un buen empleo (88%)",
        // ],
      },
      {
        description:
          "Dominar las matemáticas es un valor añadido para encontrar un buen empleo",
        conclusion: {
          ES: [
            "El 84% de los padres españoles considera que dominando las matemáticas se consigue un mejor trabajo.",
          ],
          CL: [
            "El 84% de los padres chilenos considera que dominando las matemáticas se consigue un mejor trabajo.",
          ],
          MX: [
            "El 82% de los padres mexicanos considera que dominando las matemáticas se consigue un mejor trabajo.",
          ],
          CO: [
            "El 81% de los padres colombianos considera que dominando las matemáticas se consigue un mejor trabajo.",
          ],
          PE: [
            "El 85% de los padres peruanos considera que dominando las matemáticas se consigue un mejor trabajo.",
          ],
        },
      },
      {
        description:
          "Los mejores puestos de trabajo requieren un buen conocimiento de matemáticas",
        conclusion: {
          ES: [
            "7 de cada 10 padres españoles cree firmemente que tener un buen dominio de las matemáticas es un valor añadido y diferencial en el mercado laboral para conseguir un buen trabajo.",
            "Los hombres tienden a darle ligeramente más importancia a las matemáticas que las mujeres.",
            "Además, se observa que los padres que se llevaban bien con las matemáticas cuando eran alumnos valoran más la importancia de las matemáticas para encontrar un buen empleo.",
          ],
          CL: [
            "7 de cada 10 padres chilenos cree firmemente que tener un buen dominio de las matemáticas es un valor añadido y diferencial en el mercado laboral para conseguir un buen trabajo.",
            "Los hombres tienden a darle más importancia a las matemáticas que las mujeres.",
            "Además, se observa que los padres que se llevaban bien con las matemáticas cuando eran alumnos valoran más la importancia de las matemáticas para encontrar un buen empleo.",
          ],
          MX: [
            "Más de 8 de cada 10 padres mexicanos cree firmemente que tener un buen dominio de las matemáticas es un valor añadido y diferencial en el mercado laboral para conseguir un buen trabajo.",
            "Los hombres tienden a darle ligeramente más importancia a las matemáticas que las mujeres.",
            "Además, se observa que los padres que se llevaban bien con las matemáticas cuando eran alumnos valoran más la importancia de las matemáticas para encontrar un buen empleo.",
          ],
          CO: [
            "6 de cada 10 padres colombianos cree firmemente que tener un buen dominio de las matemáticas es un valor añadido y diferencial en el mercado laboral para conseguir un buen trabajo.",
            "Los hombres tienden a darle más importancia a las matemáticas que las mujeres.",
            "Además, se observa que los padres que se llevaban bien con las matemáticas cuando eran alumnos valoran más la importancia de las matemáticas para encontrar un buen empleo.",
          ],
          PE: [
            "El 65% de los padres peruanos cree firmemente que tener un buen dominio de las matemáticas es un valor añadido y diferencial en el mercado laboral para conseguir un buen trabajo.",
            "Los hombres tienden a darle mucha más importancia a las matemáticas que las mujeres (86% vs 76%)",
            "Además, se observa que los padres que se llevaban bien con las matemáticas cuando eran alumnos valoran más la importancia de las matemáticas para encontrar un buen empleo.",
          ],
        },
      },
      {
        description:
          "Las carreras técnicas tienen oportunidades laborales mejor remuneradas",
        conclusion: {
          ES: [
            "El 73% de los padres españoles está de acuerdo en que las carreras técnicas ofrecen mayores oportunidades laborales y salarios más altos.",
            "La valoración es similar independientemente de si los padres han tenido una buena o mala relación con las matemáticas durante su educación.",
          ],
          CL: [
            "El 75% de los padres chilenos está de acuerdo en que las carreras técnicas ofrecen mayores oportunidades laborales y salarios más altos.",
            "La valoración es similar independientemente de si los padres han tenido una buena o mala relación con las matemáticas durante su educación.",
          ],
          MX: [
            "El 73% de los padres mexicanos está de acuerdo en que las carreras técnicas ofrecen mayores oportunidades laborales y salarios más altos.",
            "La valoración es similar independientemente de si los padres han tenido una buena o mala relación con las matemáticas durante su educación.",
          ],
          CO: [
            "El 54% de los padres colombianos está de acuerdo en que las carreras técnicas ofrecen mayores oportunidades laborales y salarios más altos.",
            "Se observa que esta percepción es aún mayor cuando los padres han tenido una mala relación con las matemáticas durante su educación.",
          ],
          PE: [
            "El 66% de los padres peruanos está de acuerdo en que las carreras técnicas ofrecen mayores oportunidades laborales y salarios más altos.",
            "Se observa que esta percepción es aún mayor cuando los padres han tenido una buena relación con las matemáticas durante su educación.",
          ],
        },
      },
    ],
    title:
      "¿Por qué te parecen las matemáticas tan importantes en la formación de tus hijos/as?",
    description: [""],
    // colors: d3.schemePiYG[5],
    colors: d3.schemePiYG[5],
    labels: [
      "Totalmente en desacuerdo",
      "En desacuerdo",
      "Ni de acuerdo, ni en desacuerdo",
      "De acuerdo",
      "Totalmente de acuerdo",
    ],
    type: "donut",
  },
  {
    //P4
    title: "¿Qué piensan tus hijos/as sobre las matemáticas?",
    description: {
      ES: [
        "Casi la mitad de los niños españoles encuentran las matemáticas aburridas o indiferentes.",
        "Existe una brecha de género en cuanto a la percepción de las matemáticas, ya que las niñas las encuentran significativamente más aburridas que los niños, un 53% de las niñas en comparación con el 38% de los niños.",
        "Los niños cuyos padres no disfrutaron de las matemáticas en el colegio son más propensos a encontrarlas aburridas o indiferentes en comparación con aquellos cuyos padres sí disfrutaron de las matemáticas en la escuela, un 53% frente al 39%. ",
      ],
      CL: [
        "Cuatro de cada diez niños chilenos encuentran las matemáticas aburridas o indiferentes.",
        "Existe una brecha de género en cuanto a la percepción de las matemáticas, ya que las niñas las encuentran significativamente más aburridas que los niños, un 50% de las niñas en comparación con el 36% de los niños.",
        "Los niños cuyos padres no disfrutaron de las matemáticas en el colegio son más propensos a encontrarlas aburridas o indiferentes en comparación con aquellos cuyos padres sí disfrutaron de las matemáticas en la escuela, un 50% frente al 36%. ",
      ],
      MX: [
        "Cuatro de cada diez niños mexicanos encuentran las matemáticas aburridas o indiferentes.",
        "Existe una brecha de género en cuanto a la percepción de las matemáticas, ya que las niñas las encuentran significativamente más aburridas que los niños, un 47% de las niñas en comparación con el 35% de los niños.",
        "Los niños cuyos padres no disfrutaron de las matemáticas en el colegio son más propensos a encontrarlas aburridas o indiferentes en comparación con aquellos cuyos padres sí disfrutaron de las matemáticas en la escuela, un 50% frente al 34%. ",
      ],
      CO: [
        "Cuatro de cada diez niños colombianos encuentran las matemáticas aburridas o indiferentes.",
        "Existe una brecha de género en cuanto a la percepción de las matemáticas, ya que las niñas las encuentran significativamente más aburridas que los niños, un 43% de las niñas en comparación con el 27% de los niños.",
        "Los niños cuyos padres no disfrutaron de las matemáticas en el colegio son más propensos a encontrarlas aburridas o indiferentes en comparación con aquellos cuyos padres sí disfrutaron de las matemáticas en la escuela, un 45% frente al 29%. ",
      ],
      PE: [
        "Cuatro de cada diez niños peruanos encuentran las matemáticas aburridas o indiferentes.",
        "Existe una brecha de género en cuanto a la percepción de las matemáticas, ya que las niñas las encuentran significativamente más aburridas que los niños, un 39% de las niñas en comparación con el 29% de los niños.",
        "Los niños cuyos padres no disfrutaron de las matemáticas en el colegio son más propensos a encontrarlas aburridas o indiferentes en comparación con aquellos cuyos padres sí disfrutaron de las matemáticas en la escuela, un 45% frente al 28%. ",
      ],
    },
    labels: [
      "Les resulta indiferentes",
      "Son difíciles y aburridas",
      "Son estimulantes y divertidas",
    ],
    type: "stacked",
    colors: d3.schemeTableau10,
  },
  {
    //P5
    title:
      "¿Consideras que es importante hacer más atractivo el aprendizaje de las matemáticas para que les guste más a los niños/as?",
    description: {
      ES: [
        "Nueve de cada 10 españoles consideran muy importante hacer más atractivo el aprendizaje de las matemáticas.",
        "Independientemente del género o del nivel de estudios.",
      ],
      CL: [
        "Nueve de cada 10 chilenos consideran muy importante hacer más atractivo el aprendizaje de las matemáticas.",
        "Independientemente del género o del nivel de estudios.",
      ],
      MX: [
        "Nueve de cada 10 mexicanos consideran muy importante hacer más atractivo el aprendizaje de las matemáticas.",
        "Independientemente del género o del nivel de estudios.",
      ],
      CO: [
        "Nueve de cada 10 colombianos consideran muy importante hacer más atractivo el aprendizaje de las matemáticas.",
        "Independientemente del género o del nivel de estudios.",
      ],
      PE: [
        "Nueve de cada 10 peruanos consideran muy importante hacer más atractivo el aprendizaje de las matemáticas.",
        "Independientemente del género o del nivel de estudios.",
      ],
    },
    labels: [
      "Totalmente en desacuerdo",
      "En desacuerdo",
      "Ni de acuerdo, ni en desacuerdo",
      "De acuerdo",
      "Totalmente de acuerdo",
    ],
    // colors: d3.schemePiYG[5],
    colors: d3.schemePiYG[5],
    type: "stacked",
  },
  {
    //P6
    subitems: [
      // {
      //   description:
      //     "Malas experiencias de los padres y sus comentarios en casa.",
      //   conclusion: {
      //     ES: [],
      //     CL: [],
      //     MX: [],
      //     CO: [],
      //     PE: [],
      //   },
      // },
      {
        description:
          "Inadecuada preparación matemática de algunos maestros de primaria.",
        conclusion: {
          ES: [
            "El 51% de los padres españoles opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
          ],
          CL: [
            "El 52% de los padres chilenos opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
          ],
          MX: [
            "El 52% de los padres mexicanos opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
          ],
          CO: [
            "El 52% de los padres colombianos opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
          ],
          PE: [
            "El 52% de los padres peruanos opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
          ],
        },
      },
      {
        description:
          "Escasez de medios de refuerzo en la escuela para atender a las necesidades de todos los alumnos.",
        conclusion: {
          ES: [
            "El 67% de los padres españoles cree que las matemáticas resultan aburridas para los niños por la falta de recursos de refuerzo escolar que atiendan las necesidades de todos los alumnos.",
          ],
          CL: [
            "El 67% de los padres chilenos cree que las matemáticas resultan aburridas para los niños por la falta de recursos de refuerzo escolar que atiendan las necesidades de todos los alumnos.",
          ],
          MX: [
            "El 67% de los padres mexicanos cree que las matemáticas resultan aburridas para los niños por la falta de recursos de refuerzo escolar que atiendan las necesidades de todos los alumnos.",
          ],
          CO: [
            "El 67% de los padres colombianos cree que las matemáticas resultan aburridas para los niños por la falta de recursos de refuerzo escolar que atiendan las necesidades de todos los alumnos.",
          ],
          PE: [
            "El 67% de los padres peruanos cree que las matemáticas resultan aburridas para los niños por la falta de recursos de refuerzo escolar que atiendan las necesidades de todos los alumnos.",
          ],
        },
      },
      {
        description:
          "Demasiados alumnos en el salón, con muy distintos niveles de conocimiento y dominio de las matemáticas.",
        conclusion: {
          ES: [
            "7 de cada 10 padres opina que hay demasiados alumnos por clase, con muy distintos niveles de conocimiento y dominio de las matemáticas.",
          ],
          CL: [
            "7 de cada 10 padres opina que hay demasiados alumnos por clase, con muy distintos niveles de conocimiento y dominio de las matemáticas.",
          ],
          MX: [
            "7 de cada 10 padres opina que hay demasiados alumnos por clase, con muy distintos niveles de conocimiento y dominio de las matemáticas.",
          ],
          CO: [
            "7 de cada 10 padres opina que hay demasiados alumnos por clase, con muy distintos niveles de conocimiento y dominio de las matemáticas.",
          ],
          PE: [
            "7 de cada 10 padres opina que hay demasiados alumnos por clase, con muy distintos niveles de conocimiento y dominio de las matemáticas.",
          ],
        },
      },
      {
        description:
          "Insuficiente presencia de tecnología en los salones: computadoras, tablets, pizarras digitales.",
        conclusion: {
          ES: [
            "El 41% de los padres considera que en las aulas hay una insuficiente presencia de tecnología (ordenadores, tablets, etc.).",
          ],
          CL: [
            "El 43% de los padres chilenos considera que en las aulas hay una insuficiente presencia de tecnología (ordenadores, tablets, etc.).",
          ],
          MX: [
            "El 43% de los padres mexicanos considera que en las aulas hay una insuficiente presencia de tecnología (ordenadores, tablets, etc.).",
          ],
          CO: [
            "El 43% de los padres colombianos considera que en las aulas hay una insuficiente presencia de tecnología (ordenadores, tablets, etc.).",
          ],
          PE: [
            "El 43% de los padres peruanos considera que en las aulas hay una insuficiente presencia de tecnología (ordenadores, tablets, etc.).",
          ],
        },
      },
      {
        description:
          "Dedicación prioritaria del profesor a los niños que tienen problemas de aprendizaje en detrimento de los que dominan la materia o los que progresan adecuadamente.",
        conclusion: {
          ES: [
            "Casi la mitad de los padres españoles cree que los profesores priorizan a los estudiantes con dificultades de aprendizaje, en detrimento de aquellos que ya dominan la materia.",
          ],
          CL: [
            "Casi la mitad de los padres chilenos cree que los profesores priorizan a los estudiantes con dificultades de aprendizaje, en detrimento de aquellos que ya dominan la materia.",
          ],
          MX: [
            "Casi la mitad de los padres mexicanos cree que los profesores priorizan a los estudiantes con dificultades de aprendizaje, en detrimento de aquellos que ya dominan la materia.",
          ],
          CO: [
            "Casi la mitad de los padres colombianos cree que los profesores priorizan a los estudiantes con dificultades de aprendizaje, en detrimento de aquellos que ya dominan la materia.",
          ],
          PE: [
            "Casi la mitad de los padres peruanos cree que los profesores priorizan a los estudiantes con dificultades de aprendizaje, en detrimento de aquellos que ya dominan la materia.",
          ],
        },
      },
    ],
    title:
      "¿Por qué piensan tus hijos/as que las matemáticas son aburridas y difíciles?",

    colors: d3.schemePiYG[5],
    // colors: d3.schemePiYG[5],
    labels: [
      "Totalmente en desacuerdo",
      "En desacuerdo",
      "Ni de acuerdo, ni en desacuerdo",
      "De acuerdo",
      "Totalmente de acuerdo",
    ],
    type: "donut",
  },
  {
    //P7
    subitems: [
      {
        description:
          "Personalizar la tarea de cada alumno a su nivel de conocimiento y sus capacidades.",
        conclusion: {
          ES: [
            "El 90% de los padres españoles considera que para conseguir más alumnos excelentes en matemáticas, es necesario personalizar la tarea de cada alumno a su nivel de conocimiento y capacidades.",
          ],
          CL: [
            "El 90% de los padres chilenos considera que para conseguir más alumnos excelentes en matemáticas, es necesario personalizar la tarea de cada alumno a su nivel de conocimiento y capacidades.",
          ],
          MX: [
            "El 90% de los padres mexicanos considera que para conseguir más alumnos excelentes en matemáticas, es necesario personalizar la tarea de cada alumno a su nivel de conocimiento y capacidades.",
          ],
          CO: [
            "El 90% de los padres colombianos considera que para conseguir más alumnos excelentes en matemáticas, es necesario personalizar la tarea de cada alumno a su nivel de conocimiento y capacidades.",
          ],
          PE: [
            "El 90% de los padres peruanos considera que para conseguir más alumnos excelentes en matemáticas, es necesario personalizar la tarea de cada alumno a su nivel de conocimiento y capacidades.",
          ],
        },
      },
      {
        description: "Contar con más tecnología en los colegios y salones.",
        conclusion: {
          ES: [
            "El 69% de los padres españoles opina que la personalización de la tarea se conseguirá contando con más tecnología en los colegios y salones.",
          ],
          CL: [
            "El 70% de los padres opina que la personalización de la tarea se conseguirá contando con más tecnología en los colegios y salones.",
          ],
          MX: [
            "El 71% de los padres opina que la personalización de la tarea se conseguirá contando con más tecnología en los colegios y salones.",
          ],
          CO: [
            "El 72% de los padres opina que la personalización de la tarea se conseguirá contando con más tecnología en los colegios y salones.",
          ],
          PE: [
            "El 86% de los padres peruanos opina que la personalización de la tarea se conseguirá contando con más tecnología en los colegios y salones.",
          ],
        },
      },
      {
        description:
          "Incrementar el número de horas de matemáticas en el colegio.",
        conclusion: {
          ES: [
            "6 de cada 10 padres se muestra a favor de aumentar el número de horas de clase de matemáticas.",
          ],
          CL: [
            "6 de cada 10 padres chilenos se muestra a favor de aumentar el número de horas de clase de matemáticas.",
          ],
          MX: [
            "6 de cada 10 padres mexicanos se muestra a favor de aumentar el número de horas de clase de matemáticas.",
          ],
          CO: [
            "6 de cada 10 padres colombianos se muestra a favor de aumentar el número de horas de clase de matemáticas.",
          ],
          PE: [
            "6 de cada 10 padres peruanos se muestra a favor de aumentar el número de horas de clase de matemáticas.",
          ],
        },
      },
      {
        description:
          "Dividir los salones en grupos para poder atender a las necesidades de todos los alumnos: nivel alto, medio y bajo.",
        conclusion: {
          ES: [
            "El 76% de los padres españoles se muestra a favor de dividir las aulas en grupos para poder atender a las necesidades de todos los alumnos, según su nivel de conocimiento: alto, medio y bajo.",
          ],
          CL: [
            "El 77% de los padres chilenos se muestra a favor de dividir las aulas en grupos para poder atender a las necesidades de todos los alumnos, según su nivel de conocimiento: alto, medio y bajo.",
          ],
          MX: [
            "El 77% de los padres mexicanos se muestra a favor de dividir las aulas en grupos para poder atender a las necesidades de todos los alumnos, según su nivel de conocimiento: alto, medio y bajo.",
          ],
          CO: [
            "El 77% de los padres colombianos se muestra a favor de dividir las aulas en grupos para poder atender a las necesidades de todos los alumnos, según su nivel de conocimiento: alto, medio y bajo.",
          ],
          PE: [
            "El 77% de los padres peruanos se muestra a favor de dividir las aulas en grupos para poder atender a las necesidades de todos los alumnos, según su nivel de conocimiento: alto, medio y bajo.",
          ],
        },
      },
      {
        description:
          "Incrementar el contacto entre profesores y padres para buscar soluciones fuera del salón, cuando sea necesario.",
        conclusion: {
          ES: [
            "8 de cada 10 padres echan de menos un mayor contacto con los profesores para buscar soluciones fuera del colegio.",
          ],
          CL: [
            "8 de cada 10 padres chilenos echan de menos un mayor contacto con los profesores para buscar soluciones fuera del colegio.",
          ],
          MX: [
            "8 de cada 10 padres mexicanos echan de menos un mayor contacto con los profesores para buscar soluciones fuera del colegio.",
          ],
          CO: [
            "8 de cada 10 padres colombianos echan de menos un mayor contacto con los profesores para buscar soluciones fuera del colegio.",
          ],
          PE: [
            "8 de cada 10 padres peruanos echan de menos un mayor contacto con los profesores para buscar soluciones fuera del colegio.",
          ],
        },
      },
      {
        description:
          "Hacer más estimulante el aprendizaje de las matemáticas en los colegios: con retos, juegos y “recompensas”.",
        conclusion: {
          ES: [
            "El 95% de los padres españoles está a favor de incorporar retos, juegos y recompensas como herramientas para hacer el proceso de aprendizaje más estimulante.",
          ],
          CL: [
            "El 95% de los padres chilenos está a favor de incorporar retos, juegos y recompensas como herramientas para hacer el proceso de aprendizaje más estimulante.",
          ],
          MX: [
            "El 95% de los padres mexicanos está a favor de incorporar retos, juegos y recompensas como herramientas para hacer el proceso de aprendizaje más estimulante.",
          ],
          CO: [
            "El 95% de los padres colombianos está a favor de incorporar retos, juegos y recompensas como herramientas para hacer el proceso de aprendizaje más estimulante.",
          ],
          PE: [
            "El 95% de los padres peruanos está a favor de incorporar retos, juegos y recompensas como herramientas para hacer el proceso de aprendizaje más estimulante.",
          ],
        },
      },
    ],
    title: "¿Cómo conseguir más alumnos excelentes en matemáticas?",

    // colors: d3.schemeBlues[5],
    colors: d3.schemePiYG[5],
    labels: [
      "Totalmente en desacuerdo",
      "En desacuerdo",
      "Ni de acuerdo, ni en desacuerdo",
      "De acuerdo",
      "Totalmente de acuerdo",
    ],
    type: "donut",
  },
  {
    title:
      "Los estudios demuestran que acceden menos mujeres que hombres a carreras técnicas. ¿Es importante promover un plan de acción para conseguir más mujeres en dichas carreras, estimulando a las niñas desde el colegio?",
    description: {
      ES: [
        "Urge tomar medidas para fomentar el interés de las niñas desde edades tempranas en carreras técnicas y reducir la brecha de género en estos campos.",
        "El 77% de los padres españoles considera muy importante (58%) o importante (19%) promover un plan de acción para conseguir más mujeres en carreras técnicas, estimulándolas desde niñas.",
        "Las madres españolas valoran más la idea de estimular a las niñas, con un respaldo del 80%, lo que supone 11 puntos porcentuales más que los hombres, que apoyan esta idea en un 69%.",
      ],
      CL: [
        "Urge tomar medidas para fomentar el interés de las niñas desde edades tempranas en carreras técnicas y reducir la brecha de género en estos campos.",
        "El 74% de los padres chilenos considera muy importante (56%) o importante (18%) promover un plan de acción para conseguir más mujeres en carreras técnicas, estimulándolas desde niñas.",
        "Las madres chilenas valoran más la idea de estimular a las niñas, con un respaldo del 78%, lo que supone 16 puntos porcentuales más que los hombres, que apoyan esta idea en un 62%.",
      ],
      MX: [
        "Urge tomar medidas para fomentar el interés de las niñas desde edades tempranas en carreras técnicas y reducir la brecha de género en estos campos.",
        "El 78% de los padres mexicanos considera muy importante (58%) o importante (20%) promover un plan de acción para conseguir más mujeres en carreras técnicas, estimulándolas desde niñas.",
        "Las madres mexicanos valoran más la idea de estimular a las niñas, con un respaldo del 82%, lo que supone 18 puntos porcentuales más que los hombres, que apoyan esta idea en un 64%",
      ],
      CO: [
        "Urge tomar medidas para fomentar el interés de las niñas desde edades tempranas en carreras técnicas y reducir la brecha de género en estos campos.",
        "El 81% de los padres colombianos considera muy importante (58%) o importante (23%) promover un plan de acción para conseguir más mujeres en carreras técnicas, estimulándolas desde niñas.",
        "Las madres colombianos valoran más la idea de estimular a las niñas, con un respaldo del 81%, lo que supone 2 puntos porcentuales más que los hombres, que apoyan esta idea en un 79%.",
      ],
      PE: [
        "Urge tomar medidas para fomentar el interés de las niñas desde edades tempranas en carreras técnicas y reducir la brecha de género en estos campos.",
        "El 88% de los padres peruanos considera muy importante (67%) o importante (21%) promover un plan de acción para conseguir más mujeres en carreras técnicas, estimulándolas desde niñas.",
        "Las madres peruanas valoran más la idea de estimular a las niñas, con un respaldo del 89%, lo que supone 14 puntos porcentuales más que los hombres, que apoyan esta idea en un 75%.",
      ],
    },
    labels: [
      "Totalmente en desacuerdo",
      "En desacuerdo",
      "Ni de acuerdo, ni en desacuerdo",
      "De acuerdo",
      "Totalmente de acuerdo",
    ],
    // colors: d3.schemeBlues[5],
    colors: d3.schemePiYG[5],
    type: "stacked",
  },
  {
    //P9
    title:
      "¿Cuál debería ser su papel como padre/madre para ayudar a sus hijos/as a mejorar sus calificaciones en matemáticas?",
    description: {
      ES: [
        "6 de cada 10 padres españoles acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas",
        "El 51% de los padres cree que su papel para ayudar a sus hijos a mejorar sus calificaciones en matemáticas es inscribirlos en métodos extraescolares para dominar las matemáticas.",
        "El 38% de los padres se decanta por vigilar que sus hijos hagan todos los días sus tareas para mejorar sus calificaciones en matemáticas.",
        "El 35% de los padres prefiere ayudarlos personalmente a hacer las tareas cada día en casa como medida para mejorar las calificaciones en matemáticas, mientras que el 13% prefiere buscar un profesor particular que les resuelva sus dudas y dificultades.",
      ],
      CL: [
        "Seis de cada diez padres chilenos acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas",
        "El 50% de los padres cree que su papel para ayudar a sus hijos a mejorar sus calificaciones en matemáticas es inscribirlos en métodos extraescolares para dominar las matemáticas.",
        "El 40% de los padres prefiere vigilar que sus hijos hagan sus tareas diarias en casa como medida para mejorar las calificaciones en matemáticas, mientras que el 38% prefiere ayudar a sus hijos con sus tareas y el 13% se decanta por un profesor particular.",
      ],
      MX: [
        "Seis de cada diez padres mexicanos acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas",
        "El 48% de los padres cree que su papel para ayudar a sus hijos a mejorar sus calificaciones en matemáticas es inscribirlos en métocos extraescolares que les permitan dominar las matemáticas.",
        "El 38% de los padres prefiere vigilar que sus hijos hagan sus tareas diarias en casa como medida para mejorar las calificaciones en matemáticas, mientras que el 35% prefiere ayudar a sus hijos con sus tareas y un 13% se decanta por buscar un profesor particular.",
      ],
      CO: [
        "1 de cada 2 los padres colombianos acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas",
        "El 41% de los padres cree que su papel para ayudar a sus hijos a mejorar sus calificaciones en matemáticas es ayudarlos personalmente a hacer las tareas cada día en casa.",
        "El 38% de los padres prefiere inscribirlos en métodos extraescolares para dominar las matemáticas, como medida para mejorar las calificaciones en matemáticas, mientras que el 24% se decanta por vigilar que sus hijos hagan sus tareas diarias en casa y el 13% prefieren buscar un profesor particular.",
      ],
      PE: [
        "Siete de cada diez padres peruanos acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas",
        "El 49% de los padres cree que su papel para ayudar a sus hijos a mejorar sus calificaciones en matemáticas es inscribirlos en métodos extraescolares que les permita dominar las matemáticas.",
        "El 43% de los padres prefiere ayudar personalmente a sus hijos con sus tareas como medida para mejorar las calificaciones en matemáticas, mientras que el 29% se decanta por vigilar que sus hijos hagan sus tareas diarias en casa y el 19% buscar un profesor particular.",
      ],
    },
    colors: d3.schemeTableau10,
    labels: [
      // "Estar en contacto con sus profesores y atender a las sugerencias de mejora que planteen.",
      "Vigilar que hagan todos los días sus tareas.",
      "Ayudarlos personalmente a hacer las tareas cada día en casa.",
      "Inscribirlos en métodos extraescolares que les permitan dominar mejor las matemáticas.",
      "Buscar un profesor particular que les resuelvan sus dudas y dificultades.",
    ],
    type: "progress",
    legend: "rows",
  },
  {
    //P10
    title:
      "¿Es más importante aprender un segundo idioma (inglés) o dominar las matemáticas?",
    description: {
      ES: [
        "9 de cada 10 padres españoles consideran las matemáticas igual o más importantes que el inglés ",
        "El 87% de los padres españoles considera que las matemáticas son igual o más importantes que dominar el inglés.",
        "Las mujeres consideran las matemáticas más importantes que los hombres, con un 88% frente a un 85%.",
        "Los padres que eran buenos alumnos en matemáticas le otorgan aún más importancia a las matemáticas.",
      ],
      CL: [
        "9 de cada 10 padres chilenos consideran las matemáticas igual o más importantes que el inglés ",
        "El 89% de los padres chilenos considera que las matemáticas son igual o más importantes que dominar el inglés.",
        "Las mujeres consideran las matemáticas más importantes que los hombres, con un 89% frente a un 88%.",
        "Los padres que eran buenos alumnos en matemáticas le otorgan aún más importancia a las matemáticas.",
      ],
      MX: [
        "9 de cada 10 padres mexicanos consideran las matemáticas igual o más importantes que el inglés",
        "El 86% de los padres mexicanos considera que las matemáticas son igual o más importantes que dominar el inglés.",
        "Los hombres consideran las matemáticas más importantes que las mujeres, con un 88% frente a un 86%.",
        "Los padres que eran buenos alumnos en matemáticas le otorgan aún más importancia a las matemáticas.",
      ],
      CO: [
        "7 de cada 10 padres colombianos consideran las matemáticas igual o más importantes que el inglés",
        "El 74% de los padres colombianos considera que las matemáticas son igual o más importantes que dominar el inglés.",
        "Las mujeres consideran las matemáticas más importantes que los hombres, con un 78% frente a un 63%.",
        "Los padres que eran buenos alumnos en matemáticas le otorgan aún más importancia a las matemáticas.",
      ],
      PE: [
        "7 de cada 10 padres peruanos consideran las matemáticas igual o más importantes que el inglés",
        "El 73% de los padres peruanos considera que las matemáticas son igual o más importantes que dominar el inglés.",
        "Los hombres consideran las matemáticas más importantes que las mujeres, con un 88% frente a un 71%.",
        "Los padres que eran buenos alumnos en matemáticas le otorgan aún más importancia a las matemáticas.",
      ],
    },
    labels: ["Matemáticas", "Igual de importantes", "Inglés"],
    colors: [
      d3.schemeTableau10[2],
      d3.schemeTableau10[1],
      d3.schemeTableau10[0],
    ],
    type: "stacked",
  },
];
