import { useFilterContext } from "../../../context";
import { countryFilters as countryFiltersInitial } from "../filters";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const CountryFilter = () => {
  const { countryFilters, setCountryFilters } = useFilterContext();

  return (
    <FormControl fullWidth>
      <InputLabel>País</InputLabel>
      <Select
        value={countryFilters}
        label={"País"}
        onChange={(event) => {
          setCountryFilters(event.target.value);
        }}
      >
        {countryFiltersInitial.map((country) => (
          <MenuItem key={country.value} value={country.value}>
            {country.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountryFilter;
