import * as d3 from "d3";

const countryMap = {
  // BR: "Brazil",
  CO: "Colombia",
  MX: "Mexico",
  PE: "Perú",
  CL: "Chile",
  ES: "España",
};

const propMap = {
  country: "País",
  sex: "¿Es usted...?",
  education: "Nivel de estudios:",
  haveKids: "¿Tiene hijos/as?",
  formationImportance:
    "¿Qué tan importantes le parecen que son las matemáticas en la formación de sus hijos/as?",
  mathInDailyLife:
    "Las matemáticas son cada vez más esenciales en la vida diaria",
  mathForGoodJob:
    "Dominar las matemáticas es un valor añadido para encontrar un buen empleo",
  mathForBestJobs:
    "Los mejores puestos de trabajo requieren un buen conocimiento de matemáticas",
  mathForTechCareers:
    "Las carreras técnicas tienen oportunidades laborales mejor remuneradas",
  childrenThinkOfMath: "¿Qué piensan sus hijos/as sobre las matemáticas?",
  makeMathAttractive:
    "¿Considera que es importante hacer más atractivo el aprendizaje de las matemáticas para que les guste más a los niños/as?",

  step4_1: "Malas experiencias de los padres y sus comentarios en casa.",
  step4_2: "Inadecuada preparación matemática de algunos maestros de primaria.",
  step4_3:
    "Escasez de medios de refuerzo en la escuela para atender a las necesidades de todos los alumnos.",
  step4_4:
    "Demasiados alumnos en el salón, con muy distintos niveles de conocimiento y dominio de las matemáticas.",
  step4_5:
    "Insuficiente presencia de tecnología en los salones: computadoras, tablets, pizarras digitales.",
  step4_6: "Pocas horas de clase de matemáticas",
  step4_7:
    "Dedicación prioritaria del profesor a los niños que tienen problemas de aprendizaje en detrimento de los que dominan la materia o los que progresan adecuadamente.",

  step5_1:
    "Personalizar la tarea de cada alumno a su nivel de conocimiento y sus capacidades.",
  step5_2: "Contar con más tecnología en los colegios y salones.",
  step5_3: "Incrementar el número de horas de matemáticas en el colegio.",

  step5_4:
    "Dividir los salones en grupos para poder atender a las necesidades de todos los alumnos: nivel alto, medio y bajo.",
  step5_5:
    "Incrementar el contacto entre profesores y padres para buscar soluciones fuera del salón, cuando sea necesario.",
  step5_6:
    "Hacer más estimulante el aprendizaje de las matemáticas en los colegios: con retos, juegos y “recompensas”.",

  promoteWomen:
    "Los estudios demuestran que acceden menos mujeres que hombres a carreras técnicas. ¿Es importante promover un plan de acción para conseguir que más mujeres estudien dichas carreras, con el fin de incentivar a las niñas desde el colegio?",
  step7_1:
    "Estar en contacto con sus profesores y atender a las sugerencias de mejora que planteen.",
  step7_2: "Vigilar que hagan todos los días sus tareas.",
  step7_3: "Ayudarlos personalmente a hacer las tareas cada día en casa.",
  step7_4:
    "Inscribirlos en métodos extraescolares que les permitan dominar mejor las matemáticas.",
  step7_5:
    "Buscar un profesor particular que les resuelvan sus dudas y dificultades.",

  languageOrMath:
    "Como padre/madre y por su experiencia, ¿es más importante aprender un segundo idioma (inglés) o dominar las matemáticas?",
};

const stepPropMapper = {
  0: propMap.formationImportance,
  1: [
    propMap.mathInDailyLife,
    propMap.mathForGoodJob,
    propMap.mathForBestJobs,
    propMap.mathForTechCareers,
  ],
  2: propMap.childrenThinkOfMath,
  3: propMap.makeMathAttractive,
  4: [
    // propMap.step4_1,
    propMap.step4_2,
    propMap.step4_3,
    propMap.step4_4,
    propMap.step4_5,
    // propMap.step4_6,
    propMap.step4_7,
  ],
  5: [
    propMap.step5_1,
    propMap.step5_2,
    propMap.step5_3,
    propMap.step5_4,
    propMap.step5_5,
    propMap.step5_6,
  ],
  6: propMap.promoteWomen,
  7: [
    // propMap.step7_1,
    propMap.step7_4,
    propMap.step7_2,
    propMap.step7_3,
    propMap.step7_5,
  ],
  8: propMap.languageOrMath,
};

export const getMultiselectData = (stepIndex, data) => {
  let retval = {};
  let props = stepPropMapper[stepIndex];

  props.forEach((prop) => {
    retval[prop] = 0;
  });

  data.forEach((d) => {
    props.forEach((prop) => {
      if (d[prop] === 1) {
        retval[prop]++;
      }
    });
  });

  //normalize the responses
  props.forEach((prop) => {
    retval[prop] = retval[prop] / data.length;
  });

  return retval;
};

export const getDonutData = (stepIndex, data) => {
  let retval = [];
  let props = stepPropMapper[stepIndex];

  props.forEach((prop) => {
    let entry = {
      description: prop,
      responses: [0, 0, 0, 0, 0],
    };

    const nonNullData = data.filter((d) => d[prop] !== null);

    //iterate over the data and count the responses
    nonNullData.forEach((d) => {
      entry.responses[d[prop] - 1]++;
    });

    //normalize the responses
    for (let i = 0; i < entry.responses.length; i++) {
      entry.responses[i] = entry.responses[i] / nonNullData.length;
    }

    retval.push(entry);
  });

  return retval;
};

export const getStackedData = (stepIndex, data) => {
  let retval = [];
  let prop = stepPropMapper[stepIndex];

  const countryData = d3.groups(data, (d) => d[propMap.country]);
  let blendedData = countryData;
  if (countryData.length > 1) {
    blendedData = [["Global", [].concat(...countryData.map((d) => d[1]))]];
  }

  let responses = otherLevelNames;
  if (stepIndex === 0) responses = levelNames;
  if (stepIndex === 2) responses = opinions;
  else if (stepIndex === 8) responses = langOrMathOptions;

  blendedData.forEach((countryData) => {
    let entry = {
      country: countryMap[countryData[0]],
    };

    //initialize the levels to 0
    responses.forEach((response) => {
      entry[response] = 0;
    });

    const nonNullData = countryData[1].filter((d) => d[prop] !== null);

    //now we need to count the number of entries for each level
    nonNullData.forEach((d) => {
      const value = d[prop];

      if (stepIndex !== 2 && stepIndex !== 8) {
        const level =
          stepIndex === 0 ? valuesToLevels[value] : valuesToOtherLevels[value];
        entry[level] += 1;
      } else {
        entry[value] += 1;
      }
    });

    //now we need to convert the counts to percentages by dividing with the countryData[1].length
    responses.forEach((response) => {
      entry[response] /= nonNullData.length;
    });

    retval.push(entry);
  });

  return retval;
};

const opinions = [
  "Les resulta indiferentes",
  "Son difíciles y aburridas",
  "Son estimulantes y divertidas",
];

const langOrMathOptions = ["Igual de importantes", "Inglés", "Matemáticas"];

const levelsToValues = {
  "Nada Importantes": 1,
  "Poco Importantes": 2,
  Importantes: 3,
  "Muy Importantes": 4,
  "Extremadamente Importantes": 5,
};

const levelNames = Array.from(Object.keys(levelsToValues));

const valuesToLevels = {
  1: "Nada Importantes",
  2: "Poco Importantes",
  3: "Importantes",
  4: "Muy Importantes",
  5: "Extremadamente Importantes",
};

const valuesToOtherLevels = {
  1: "Totalmente en desacuerdo",
  2: "En desacuerdo",
  3: "Ni de acuerdo, ni en desacuerdo",
  4: "De acuerdo",
  5: "Totalmente de acuerdo",
};

const otherLevelstoValues = {
  "Totalmente en desacuerdo": 1,
  "En desacuerdo": 2,
  "Ni de acuerdo, ni en desacuerdo": 3,
  "De acuerdo": 4,
  "Totalmente de acuerdo": 5,
};

const otherLevelNames = Array.from(Object.keys(otherLevelstoValues));
