export const countryFilters = [
  {
    value: "ES",
    label: "España",
  },
  {
    value: "CL",
    label: "Chile",
  },
  {
    value: "MX",
    label: "Mexico",
  },
  {
    value: "CO",
    label: "Colombia",
  },
  {
    value: "PE",
    label: "Perú",
  },
];

const toUrlMap = {
  España: "espana",
  Chile: "chile",
  Mexico: "mexico",
  Colombia: "colombia",
  Perú: "peru",
};

export const getCountryLabelByCode = (value, url = false) => {
  let retval = countryFilters.find((item) => item.value === value).label;

  if (url) {
    retval = toUrlMap[retval];
  }

  return retval;
};

export const sexFilters = [
  {
    value: "Mujer",
    label: "Mujer",
  },
  {
    value: "Hombre",
    label: "Hombre",
  },
];

export const educationFilters = [
  {
    value: "1",
    label: "Educación Primaria",
  },
  {
    value: "2",
    label: "Educación Secundaria o equivalente",
  },
  {
    value: "3",
    label: "Estudios Universitarios o equivalente",
  },
  {
    value: "4",
    label: "Máster o Doctorado",
  },
];
