export const content = {
  PE: {
    title: "Barómetro de la educación 2023: Matemáticas",
    subtitle:
      "Smartick en colaboración con Universidades ha realizado un estudio para conocer cómo valoran los padres la enseñanza de las matemáticas",
    highlights: {
      left: [
        [
          "",
          "7 de cada 10",
          "padres consideran las matemáticas igual o más importantes que el inglés.",
        ],
        [
          "",
          "4 de cada 10",
          "niños encuentran las matemáticas aburridas o indiferentes.",
        ],
        [
          "",
          "1 de cada 4",
          "padres acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas.",
        ],
      ],
      //right
      right: [
        [
          "El",
          "52%",
          "de los padres opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
        ],
        [
          "",
          "6 de cada 10",
          "padres se muestra a favor de aumentar el número de horas de clase de matemáticas.",
        ],
        [
          "El",
          "77%",
          "de los padres peruanos apoya dividir el salón en grupos según el nivel de conocimiento de los alumnos.",
        ],
      ],
    },
  },
  CO: {
    title: "Barómetro de la educación 2023: Matemáticas",
    subtitle:
      "Smartick en colaboración con Universidades ha realizado un estudio para conocer cómo valoran los padres la enseñanza de las matemáticas",
    highlights: {
      left: [
        [
          "",
          "7 de cada 10",
          "padres consideran las matemáticas igual o más importantes que el inglés.",
        ],
        [
          "",
          "4 de cada 10",
          "niños encuentran las matemáticas aburridas o indiferentes.",
        ],
        [
          "",
          "1 de cada 4",
          "padres acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas.",
        ],
      ],
      //right
      right: [
        [
          "El",
          "52%",
          "de los padres opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
        ],
        [
          "",
          "6 de cada 10",
          "padres se muestra a favor de aumentar el número de horas de clase de matemáticas.",
        ],
        [
          "El",
          "77%",
          "de los padres colombianos apoya dividir el salón en grupos según el nivel de conocimiento de los estudiantes.",
        ],
      ],
    },
  },
  MX: {
    title: "Barómetro de la educación 2023: Matemáticas",
    subtitle:
      "Smartick en colaboración con Universidades ha realizado un estudio para conocer cómo valoran los padres la enseñanza de las matemáticas",
    highlights: {
      left: [
        [
          "",
          "9 de cada 10",
          "padres consideran las matemáticas igual o más importantes que el inglés.",
        ],
        [
          "",
          "4 de cada 10",
          "niños encuentran las matemáticas aburridas o indiferentes.",
        ],
        [
          "",
          "1 de cada 4",
          "padres acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas.",
        ],
      ],
      //right
      right: [
        [
          "El",
          "52%",
          "de los padres opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
        ],
        [
          "",
          "6 de cada 10",
          "padres se muestra a favor de aumentar el número de horas de clase de matemáticas.",
        ],
        [
          "El",
          "77%",
          "de los padres mexicanos apoya dividir el salón en grupos según el nivel de conocimiento de los alumnos.",
        ],
      ],
    },
  },
  CL: {
    title: "Barómetro de la educación 2023: Matemáticas",
    subtitle:
      "Smartick en colaboración con Universidades ha realizado un estudio para conocer cómo valoran los padres la enseñanza de las matemáticas",
    highlights: {
      left: [
        [
          "",
          "9 de cada 10",
          "padres consideran las matemáticas igual o más importantes que el inglés.",
        ],
        [
          "",
          "4 de cada 10",
          "niños encuentran las matemáticas aburridas o indiferentes.",
        ],
        [
          "",
          "1 de cada 4",
          "padres acude a métodos extracurriculares y profesores particulares para que sus hijos dominen las matemáticas.",
        ],
      ],
      //right
      right: [
        [
          "El",
          "52%",
          "de los padres opina que algunos profesores de enseñanza básica no están suficientemente preparados para enseñar matemáticas.",
        ],
        [
          "",
          "6 de cada 10",
          "padres se muestra a favor de aumentar el número de horas de clase de matemáticas.",
        ],
        [
          "El",
          "77%",
          "de los padres chilenos apoya dividir las salas en grupos según el nivel de conocimiento de los alumnos.",
        ],
      ],
    },
  },
  ES: {
    title: "Barómetro de la educación 2023: Matemáticas",
    subtitle:
      "Smartick en colaboración con Universidades ha realizado un estudio para conocer cómo valoran los padres la enseñanza de las matemáticas",
    highlights: {
      left: [
        [
          "",
          "9 de cada 10",
          "padres consideran las matemáticas igual o más importantes que el inglés.",
        ],
        [
          "El",
          "45%",
          "de los niños encuentran las matemáticas aburridas o indiferentes.",
        ],
        [
          "",
          "1 de cada 3",
          "padres acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas.",
        ],
      ],
      //right
      right: [
        [
          "El",
          "51%",
          "de los padres opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
        ],
        [
          "",
          "6 de cada 10",
          "padres se muestra a favor de aumentar el número de horas de clase de matemáticas.",
        ],
        [
          "El",
          "76%",
          "de los padres españoles apoya dividir las clases en grupos según el nivel de conocimiento de los alumnos.",
        ],
      ],
    },
  },
  BR: {
    title: "Barómetro de la educación 2023: Matemáticas",
    subtitle:
      "Smartick en colaboración con Universidades ha realizado un estudio para conocer cómo valoran los padres la enseñanza de las matemáticas",
    highlights: {
      left: [
        [
          "",
          "9 de cada 10",
          "padres consideran las matemáticas igual o más importantes que el inglés.",
        ],
        [
          "",
          "",
          "Casi la mitad de los niños encuentran las matemáticas aburridas o indiferentes.",
        ],
        [
          "",
          "1 de cada 3",
          "padres acude a métodos extraescolares y profesores particulares para que sus hijos dominen las matemáticas.",
        ],
      ],
      //right
      right: [
        [
          "El",
          "51%",
          "de los padres opina que algunos maestros de primaria no están suficientemente preparados para enseñar matemáticas.",
        ],
        [
          "",
          "6 de cada 10",
          "padres se muestra a favor de aumentar el número de horas de clase de matemáticas.",
        ],
        [
          "El",
          "76%",
          "de los padres españoles apoya dividir las aulas en grupos según el nivel de conocimiento de los alumnos.",
        ],
      ],
    },
  },
};
