import { Box } from "@mui/system";
import { Scrollama, Step } from "react-scrollama";
import { Collapse, Container, Paper, Stack, Typography } from "@mui/material";
import { CustomLinearProgress } from "./scrollytell.styles";
import { steps } from "./steps";
import { useStepContext } from "../../context";
import { useFilterContext } from "../../context";
import SmartickScroller from "../SmartickScroller";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useData } from "../../useData";
import FilterBottom from "../FilterBottom";

const Scrollytell = () => {
  const {
    currentStepIndex,
    setCurrentStepIndex,
    setPreviousStepIndex,
    currentSubitemIndex,
    setCurrentSubitemIndex,
  } = useStepContext();

  const { data, loading } = useData();
  const { countryFilters } = useFilterContext();

  if (loading) {
    return;
  }

  const onStepEnter = ({ data }) => {
    setPreviousStepIndex(currentStepIndex);
    setCurrentStepIndex(data);
    setCurrentSubitemIndex(0);
  };

  const renderStepItem = (
    title,
    descriptions,
    index,
    subitemIndex,
    collapsible = false
  ) => {

    // console.log('hi, im in renderStepItem', countryFilters )
    return (
      <Paper
        elevation={collapsible ? 0 : index === currentStepIndex ? 8 : 0}
        sx={{ borderRadius: 2 }}
      >
        <Stack spacing={"20px"} p={collapsible ? 0 : 4}>
          <Typography
            variant={"h6"}
            color={
              index !== currentStepIndex ||
              (collapsible && subitemIndex !== currentSubitemIndex)
                ? "text.disabled"
                : "headerColor"
            }
          >
            {title}
          </Typography>
          <Collapse
            in={collapsible ? currentSubitemIndex === subitemIndex : true}
          >
            {Array.isArray(descriptions) ? (
              <Stack spacing={1}>
                {descriptions.map((description, itemIndex) => (
                  <Typography
                    variant={"body1"}
                    key={itemIndex}
                    color={
                      index !== currentStepIndex
                        ? "text.disabled"
                        : "text.primary"
                    }
                  >
                    {description}
                  </Typography>
                ))}
              </Stack>
            ) : (
              <Typography
                variant={"body1"}
                color={
                  currentSubitemIndex !== subitemIndex
                    ? "text.disabled"
                    : "text.primary"
                }
              >
                {descriptions && descriptions.length > 0
                  ? descriptions[0]
                  : `No data `}
              </Typography>
            )}
          </Collapse>
        </Stack>
      </Paper>
    );
  };

  const renderStaticStepItem = (descriptions, index) => {
    return (
      <Paper
        elevation={index === currentStepIndex ? 8 : 0}
        sx={{ borderRadius: 2 }}
      >
        <Stack spacing={4} p={4}>
          {descriptions.map((item) => (
            <Typography
              variant={"h6"}
              key={item}
              sx={{ fontSize: 18, lineHeight: "25px", fontWeight: 500 }}
              color={
                index !== currentStepIndex ? "text.disabled" : "text.primary"
              }
            >
              {item}
            </Typography>
          ))}
        </Stack>
      </Paper>
    );
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <CustomLinearProgress
        variant="determinate"
        value={
          currentStepIndex !== null
            ? (currentStepIndex * 100) / (steps.length - 1)
            : 0
        }
      />
      <Container maxWidth={false}>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          pt={"200px"}
          pb={"200px"}
        >
          <Box flexBasis={"30%"}>
            <Scrollama offset={0.5} onStepEnter={onStepEnter}>
              {steps.map((step, index) => (
                <Step data={index} key={index}>
                  <Box mb={"300px"} mt={"300px"}>
                    {step.subitems && step.subitems.length > 0 ? (
                      <Paper
                        elevation={index === currentStepIndex ? 8 : 0}
                        sx={{ borderRadius: 2 }}
                      >
                        <Stack direction={"column"} spacing={4} p={4}>
                          {step.subitems.map((subitem, subitemindex) => (
                            <Stack
                              direction={"row"}
                              spacing={"10px"}
                              sx={{ "&:hover": { cursor: "pointer" } }}
                              onClick={() => {
                                setCurrentSubitemIndex(subitemindex);
                              }}
                              key={subitemindex}
                            >
                              <ArrowForwardIcon
                                sx={{
                                  fontSize: 24,
                                  mt: "2px",
                                  color:
                                    subitemindex !== currentSubitemIndex
                                      ? "text.disabled"
                                      : "headerColor",
                                }}
                              />

                              {renderStepItem(
                                subitem.description,
                                subitem.conclusion[countryFilters],
                                index,
                                subitemindex,
                                true
                              )}
                            </Stack>
                          ))}
                        </Stack>
                      </Paper>
                    ) : (
                      renderStaticStepItem(
                        step.description[countryFilters],
                        index
                      )
                    )}
                  </Box>
                </Step>
              ))}
            </Scrollama>
          </Box>
          <Box
            flexBasis={"40%"}
            position={"sticky"}
            width={"100%"}
            height={"60vh"}
            alignSelf={"flex-start"}
            top={"80px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {!loading && <SmartickScroller data={data} />}
          </Box>
        </Box>
      </Container>

      <FilterBottom />
    </Box>
  );
};

export default Scrollytell;
