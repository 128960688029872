import { CustomAutocomplete } from "./filterAutocomplete.styles";
import { Box, Checkbox, Chip, Stack, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const FilterAutocomplete = ({
  options,
  limitTags = 1,
  value = [],
  setValue,
  label,
}) => {
  return (
    <CustomAutocomplete
      multiple
      disableCloseOnSelect
      options={options}
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={(event, newValue) => {
        setValue && setValue(newValue);
      }}
      renderTags={(tagValue, getTagProps) => {
        return (
          <Stack direction={"row"} alignItems={"center"} maxWidth={"100%"}>
            {tagValue.map((option, index) => {
              if (index < limitTags) {
                return (
                  <Chip
                    {...getTagProps({ index })}
                    label={option.label}
                    key={option.label}
                  />
                );
              } else {
                return <></>;
              }
            })}
            {tagValue.length - limitTags > 0 && (
              <Box>{`+${tagValue.length - limitTags}`}</Box>
            )}
          </Stack>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
    />
  );
};

export default FilterAutocomplete;
