import { Box, styled } from "@mui/material";

export const BottomContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease",
}));

export const FilterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 120px",
  height: 100,
  backgroundColor: theme.palette.background.default,
}));
