import * as d3 from "d3";
import { useState, useEffect } from "react";

export const useData = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const promises = [d3.tsv("/data/barometro.tsv", d3.autoType)];

    Promise.all(promises).then((data) => {
      setData(data[0]);
      setLoading(false);
    });

    console.debug("useData.js; fetched ", new Date().getMilliseconds());

    return () => undefined;
  }, []);

  return {
    data,
    loading,
  };
};
