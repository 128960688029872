import { BottomContainer, FilterWrapper } from "./filterBottom.styles";
import { Collapse, Grid } from "@mui/material";
import CountryFilter from "../filters/CountryFilter";
import SexFilter from "../filters/SexFilter";
import EducationFilter from "../filters/EducationFilter";
import FilterButton from "../FilterButton";
import { useState } from "react";

const FilterBottom = () => {
  const [isClosed, setClosed] = useState(false);

  return (
    <BottomContainer>
      <FilterButton
        isClosed={isClosed}
        onClick={() => setClosed((prev) => !prev)}
      />
      <Collapse in={!isClosed} sx={{ boxShadow: 5 }}>
        <FilterWrapper>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <CountryFilter />
            </Grid>
            <Grid item xs={3}>
              <SexFilter />
            </Grid>
            <Grid item xs={4}>
              <EducationFilter />
            </Grid>
          </Grid>
        </FilterWrapper>
      </Collapse>
    </BottomContainer>
  );
};

export default FilterBottom;
