import { Box, styled } from "@mui/material";

export const FilterButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: window.innerWidth - 140,
  height: 56,
  width: 56,
  border: "none",
  borderRadius: "74px 74px 0 0",
  backgroundColor: theme.palette.primary.main,
  transition: "all 0.3s ease",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.dark,
  },
}));
