import { Button, Stack, Typography } from "@mui/material";
import CloudsSvg from "./CloudsSvg";
import AzulImg from "./Azul.png";
import SealUniversityOfMalagaImg from "./collaborations/SealUniversityOfMalaga.png";
import UniversityOfOviedoImg from "./collaborations/UniversityOfOviedo.png";
import UAMImg from "./collaborations/UAM.png";
import CloudSvg from "./CloudSvg";
import { useFilterContext } from "../../context";
import { getCountryLabelByCode } from "../filters/filters";
import DownloadIcon from "./DownloadIcon";
import { Link } from "react-router-dom";
import PeruFlagImg from "./flags/PeruFlag.png";
import ChileFlagImg from "./flags/ChileFlag.png";
import ColombiaFlagImg from "./flags/ColombiaFlag.png";
import MexicoFlagImg from "./flags/MexicoFlag.png";
import SpainFlagImg from "./flags/SpainFlag.png";
import { useMemo } from "react";
import { content } from "../../content";

const Hero = () => {
  const { countryFilters } = useFilterContext();

  const renderStatistic = (prefix, number, description, direction) => {
    return (
      <Stack
        spacing={"4px"}
        alignItems={direction === "left" ? "flex-start" : "flex-end"}
      >
        <Stack spacing={"4px"} direction={"row"} alignItems={"center"}>
          <Typography
            color={"text.primary"}
            fontSize={34}
            lineHeight={"41px"}
            fontWeight={700}
          >
            {prefix}
          </Typography>
          <Typography
            color={"primary.main"}
            fontSize={54}
            lineHeight={"54px"}
            fontWeight={900}
          >
            {number}
          </Typography>
        </Stack>
        <Typography
          color={"text.primary"}
          fontSize={18}
          lineHeight={"27px"}
          fontWeight={600}
          textAlign={direction === "left" ? "left" : "right"}
        >
          {description}
        </Typography>
      </Stack>
    );
  };

  const StatisticBlock = ({ children }) => {
    return (
      <Stack spacing={"40px"} width={"38%"} maxWidth={450} zIndex={1}>
        {children}
      </Stack>
    );
  };

  const renderFlag = useMemo(() => {
    const style = { height: 24 };
    const alt = getCountryLabelByCode(countryFilters);

    switch (countryFilters) {
      // case "BR":
      //   return <img src={BrazilFlagImg} alt={alt} style={style} />;
      case "ES":
        return <img src={SpainFlagImg} alt={alt} style={style} />;
      case "CL":
        return <img src={ChileFlagImg} alt={alt} style={style} />;
      case "MX":
        return <img src={MexicoFlagImg} alt={alt} style={style} />;
      case "CO":
        return <img src={ColombiaFlagImg} alt={alt} style={style} />;
      case "PE":
        return <img src={PeruFlagImg} alt={alt} style={style} />;
      // default:
      //   return <img src={GlobeAmericaImg} alt={alt} style={style} />;
    }
  }, [countryFilters]);

  return (
    <Stack alignItems={"center"}>
      <Stack
        pt={"60px"}
        bgcolor={"#CDE8F8"}
        alignItems={"center"}
        justifyContent={"center"}
        position={"relative"}
        overflow={"hidden"}
        width={"100%"}
      >
        <Stack
          pl={"120px"}
          pr={"120px"}
          alignItems={"center"}
          position={"relative"}
          spacing={"20px"}
        >
          <Stack
            justifyContent={"center"}
            spacing={"22px"}
            zIndex={1}
            maxWidth={"80%"}
            alignItems={"center"}
          >
            <Stack alignItems={"center"} spacing={0}>
              <Stack alignItems={"center"} direction={"row"} spacing={1}>
                {renderFlag}
                <Typography
                  color={"primary.main"}
                  fontSize={22}
                  lineHeight={"33px"}
                  fontWeight={600}
                  textAlign={"center"}
                >
                  {getCountryLabelByCode(countryFilters)}
                </Typography>
              </Stack>
              <Typography
                color={"headerColor"}
                fontSize={54}
                lineHeight={"54px"}
                fontWeight={900}
                textAlign={"center"}
              >
                {content[countryFilters].title}
              </Typography>
            </Stack>
            <Typography
              color={"text.primary"}
              fontSize={22}
              lineHeight={"33px"}
              fontWeight={600}
              textAlign={"center"}
            >
              {content[countryFilters].subtitle}
            </Typography>

            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              color={"secondary"}
              sx={{ color: "white", height: 38, width: "fit-content" }}
              onClick={() => {
                document.getElementById("downloadReportLink").click();
              }}
            >
              Descargar informe
            </Button>
            <Link
              id={"downloadReportLink"}
              style={{ display: "none" }}
              to={`/data/${countryFilters}_ResumenEjecutivo.pdf`}
              target="_blank"
              download
            >
              Descargar informe
            </Link>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            position={"relative"}
            width={"100%"}
          >
            <StatisticBlock>
              {renderStatistic(
                content[countryFilters].highlights.left[0][0],
                content[countryFilters].highlights.left[0][1],
                content[countryFilters].highlights.left[0][2],
                "left"
              )}
              {renderStatistic(
                content[countryFilters].highlights.left[1][0],
                content[countryFilters].highlights.left[1][1],
                content[countryFilters].highlights.left[1][2],
                "left"
              )}
              {renderStatistic(
                content[countryFilters].highlights.left[2][0],
                content[countryFilters].highlights.left[2][1],
                content[countryFilters].highlights.left[2][2],
                "left"
              )}
            </StatisticBlock>
            <StatisticBlock>
              {renderStatistic(
                content[countryFilters].highlights.right[0][0],
                content[countryFilters].highlights.right[0][1],
                content[countryFilters].highlights.right[0][2],
                "right"
              )}
              {renderStatistic(
                content[countryFilters].highlights.right[1][0],
                content[countryFilters].highlights.right[1][1],
                content[countryFilters].highlights.right[1][2],
                "right"
              )}
              {renderStatistic(
                content[countryFilters].highlights.right[2][0],
                content[countryFilters].highlights.right[2][1],
                content[countryFilters].highlights.right[2][2],
                "right"
              )}
            </StatisticBlock>

            <CloudSvg
              style={{
                position: "absolute",
                left: "calc(50% - 240px/2)",
                top: 66,
              }}
            />
          </Stack>
          <CloudSvg
            style={{
              position: "absolute",
              left: -22,
              top: 87,
            }}
          />
          <CloudSvg
            style={{
              position: "absolute",
              right: -78,
              top: 87,
            }}
          />
        </Stack>
        <img
          style={{
            width: 324,
            height: 362,
            position: "absolute",
            left: "calc(50% - 324px/2 - 8.5px)",
            bottom: 40,
          }}
          src={AzulImg}
          alt={"azul"}
        />
        <CloudsSvg style={{ zIndex: 1 }} />
      </Stack>
      <Stack spacing={1} pb={2} alignItems={"center"}>
        <Typography color={"text.secondary"} variant={"body2"} fontWeight={600}>
          En Colaboración con expertos de:
        </Typography>
        <Stack spacing={"22px"} direction={"row"} alignItems={"center"}>
          <img
            src={SealUniversityOfMalagaImg}
            alt={"Seal university of malaga"}
            style={{ height: 59 }}
          />
          <img
            src={UniversityOfOviedoImg}
            alt={"University of oviedo"}
            style={{ height: 71 }}
          />
          <img src={UAMImg} alt={"UAM"} style={{ height: 63 }} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Hero;
