import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

export const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  position: "sticky",
  top: 0,
  left: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    // backgroundColor: theme.palette.grey[200],
    backgroundColor: "white",
  },
}));
