import { useFilterContext } from "../../../context";
import { educationFilters as educationFiltersInitial } from "../filters";
import FilterAutocomplete from "../FilterAutocomplete";

const EducationFilter = () => {
  const { educationFilters, setEducationFilters } = useFilterContext();

  return (
    <FilterAutocomplete
      options={educationFiltersInitial}
      limitTags={1}
      value={educationFilters}
      setValue={setEducationFilters}
      label={"Nivel de estudios"}
    />
  );
};

export default EducationFilter;
