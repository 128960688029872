import { createContext, useContext, useState } from "react";

export const defaultStepValues = {
  currentStepIndex: 0,
  previousStepIndex: 0,
  currentSubitemIndex: 0,
  setCurrentStepIndex: () => {},
  setPreviousStepIndex: () => {},
  setCurrentSubitemIndex: () => {},
};

const StepContext = createContext(defaultStepValues);

export const StepProvider = ({ children }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [previousStepIndex, setPreviousStepIndex] = useState(0);
  const [currentSubitemIndex, setCurrentSubitemIndex] = useState(0);

  return (
    <StepContext.Provider
      value={{
        currentStepIndex,
        setCurrentStepIndex,
        previousStepIndex,
        setPreviousStepIndex,
        currentSubitemIndex,
        setCurrentSubitemIndex,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export const useStepContext = () => useContext(StepContext);
