import { FilterButtonBox } from "./filterButton.styles";
import React from "react";
import { Badge } from "@mui/material";
import { useFilterContext } from "../../context";
import {
  countryFilters as countryFiltersInitial,
  educationFilters as educationFiltersInitial,
  sexFilters as sexFiltersInitial,
} from "../filters/filters";
import { useWindowSize } from "../../hooks/useWindowSize";

const FilterButton = ({ isClosed = true, onClick }) => {
  const { countryFilters, sexFilters, educationFilters } = useFilterContext();
  const size = useWindowSize();

  return (
    <FilterButtonBox
      onClick={onClick}
      component="button"
      sx={{ left: size.width - 140 }}
    >
      {isClosed ? (
        <Badge
          sx={{
            "& .MuiBadge-badge": {
              bgcolor: "white",
            },
          }}
          variant="dot"
          invisible={
            (countryFilters.length === countryFiltersInitial.length &&
              educationFilters.length === educationFiltersInitial.length &&
              sexFilters.length === sexFiltersInitial.length) ||
            (countryFilters.length === 0 &&
              educationFilters.length === 0 &&
              sexFilters.length === 0)
          }
          overlap={"circular"}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 8.16667C19.799 8.16667 24.5 7.122 24.5 5.83333C24.5 4.54467 19.799 3.5 14 3.5C8.20101 3.5 3.5 4.54467 3.5 5.83333C3.5 7.122 8.20101 8.16667 14 8.16667Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 5.83333C3.5 8.435 8.01617 13.6197 10.332 16.1058C11.1815 17.0063 11.6584 18.1952 11.6667 19.4332V22.1667C11.6667 22.7855 11.9125 23.379 12.3501 23.8166C12.7877 24.2542 13.3812 24.5 14 24.5C14.6188 24.5 15.2123 24.2542 15.6499 23.8166C16.0875 23.379 16.3333 22.7855 16.3333 22.1667V19.4332C16.3333 18.1953 16.8245 17.0123 17.668 16.1058C19.985 13.6197 24.5 8.43617 24.5 5.83333"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Badge>
      ) : (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.3333 23.3333L4.66667 4.66667M23.3333 4.66667L4.66667 23.3333"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </FilterButtonBox>
  );
};

export default FilterButton;
