import { createContext, useContext, useEffect, useState } from "react";
import {
  countryFilters as countryFiltersInitial,
  educationFilters as educationFiltersInitial,
  getCountryLabelByCode,
  sexFilters as sexFiltersInitial,
} from "../components/filters/filters";
import { useLocation, useNavigate } from "react-router-dom";

export const defaultFilterValues = {
  countryFilters: countryFiltersInitial,
  setCountryFilters: () => {},
  sexFilters: sexFiltersInitial,
  setSexFilters: () => {},
  educationFilters: educationFiltersInitial,
  setEducationFilters: () => {},
};

const FilterContext = createContext(defaultFilterValues);

export const FilterProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getInitialRoute = () => {
    const path = decodeURI(location.pathname.substring(1));

    // console.log('im here, path is: ', path)
    ///barometro-de-matematicas
    let retval;

    const fromUrlMap = {
      espana: "ES",
      chile: "CL",
      mexico: "MX",
      colombia: "CO",
      peru: "PE",
    };

    if (path) {
      //extract country name from first part of path
      const countryName = path.split("/")[0];
      //get full country name from url map
      const countryShorthand = fromUrlMap[countryName];

      retval = countryShorthand
        ? countryShorthand
        : countryFiltersInitial[0].value;
    } else {
      retval = countryFiltersInitial[0].value;
    }

    // console.log('getting initial: ', retval)
    return retval;
  };

  const [countryFilters, setCountryFilters] = useState(getInitialRoute());
  const [sexFilters, setSexFilters] = useState([]);
  const [educationFilters, setEducationFilters] = useState([]);

  useEffect(() => {
    const nextUrl = `/${getCountryLabelByCode(
      countryFilters,
      true
    )}/barometro-de-matematicas`;
    // console.log("navigating to: ", nextUrl)
    navigate(nextUrl);
  }, [countryFilters]);

  return (
    <FilterContext.Provider
      value={{
        countryFilters,
        setCountryFilters,
        sexFilters,
        setSexFilters,
        educationFilters,
        setEducationFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => useContext(FilterContext);
