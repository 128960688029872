import * as React from "react";

const DownloadIcon = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.029 1.853h-1.482A2.117 2.117 0 0 0 9.587.529H6.412c-.89 0-1.646.546-1.964 1.324H2.97a1.855 1.855 0 0 0-1.853 1.853v11.912c0 1.021.831 1.853 1.853 1.853H13.03a1.855 1.855 0 0 0 1.853-1.854V3.707a1.855 1.855 0 0 0-1.853-1.853Zm-6.618.265h3.177c.291 0 .53.238.53.529 0 .291-.24.53-.53.53H6.41a.528.528 0 0 1-.53-.53c0-.291.234-.53.53-.53Zm4.267 9.56-2.117 2.118a.79.79 0 0 1-1.122 0L5.32 11.68a.79.79 0 0 1 0-1.123.78.78 0 0 1 .559-.234c.203 0 .407.078.563.234l.762.763V7.412a.793.793 0 1 1 1.589 0v3.907l.762-.763a.78.78 0 0 1 .559-.234.796.796 0 0 1 .563 1.357Z"
      fill="#fff"
    />
  </svg>
);

export default DownloadIcon;
