import { useFilterContext } from "../../../context";
import { sexFilters as sexFiltersInitial } from "../filters";
import FilterAutocomplete from "../FilterAutocomplete";

const SexFilter = () => {
  const { sexFilters, setSexFilters } = useFilterContext();

  return (
    <FilterAutocomplete
      options={sexFiltersInitial}
      limitTags={2}
      value={sexFilters}
      setValue={setSexFilters}
      label={"Sexo"}
    />
  );
};

export default SexFilter;
