import * as React from "react";

const CloudsSvg = (props) => (
  <svg
    width={1439}
    height={159}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1439 159c-1.89-18.028-18.1-32.234-38.16-33.189-2.14-4.3-4.9-8.281-8.2-11.849-9.62-17.932-29.82-30.322-53.25-30.322-11.62 0-22.4 3.09-31.58 8.345-16.17-16.403-39.67-26.723-65.78-26.723-26.12 0-49.4 10.224-65.58 26.5-14.56-16.18-36.73-26.5-61.48-26.5-22.2 0-42.26 8.281-56.68 21.627-12.54-13.218-31.02-21.627-51.71-21.627a73.652 73.652 0 0 0-28.819 5.86C973.104 31.119 935.922-.031 890.723-.031c-45.2 0-81.612 30.545-86.863 69.944-6.757-2.93-14.285-4.65-22.267-4.65-27.554 0-50.032 19.684-51.502 44.432-3.676-.891-7.528-1.433-11.519-1.433-10.538 0-20.201 3.344-27.834 8.886-12.814-12.835-31.265-20.958-51.817-20.958a75.434 75.434 0 0 0-29.199 5.861c-12.919-37.01-50.976-63.861-95.966-63.861-36.972 0-69.287 18.155-86.793 45.196-12.604-11.211-29.865-18.123-48.876-18.123-14.144 0-27.274 3.854-38.267 10.415-9.733-29.94-40.158-51.821-76.22-51.821-18.976 0-36.377 6.052-50.031 16.117C198.024 16.084 169.385 0 136.614 0c-49.33 0-89.349 36.405-89.349 81.283 0 1.02.035 2.007.07 3.026C19.886 92.336 0 115.778 0 143.424c0 5.383.84 10.607 2.24 15.576H1439Z"
      fill="#fff"
    />
  </svg>
);

export default CloudsSvg;
