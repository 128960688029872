import Scrollytell from "./components/Scrollytell";
import Hero from "./components/Hero";
import { Box, ThemeProvider } from "@mui/material";
import { customTheme } from "./theme";
import "@fontsource/raleway/300.css";
import "@fontsource/raleway/400.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/700.css";
import "@fontsource/raleway/800.css";
import "@fontsource/raleway/900.css";
import { FilterProvider, StepProvider } from "./context";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <FilterProvider>
          <StepProvider>
            <Box>
              <Hero />
              <Scrollytell />
            </Box>
          </StepProvider>
        </FilterProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
