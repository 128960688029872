import * as React from "react";

const CloudSvg = (props) => (
  <svg
    width={240}
    height={113}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M240 71.392c0-12.527-10.157-22.691-22.676-22.691-1.114 0-2.202.111-3.266.26-4.07-15.35-18.05-26.678-34.677-26.678a35.647 35.647 0 0 0-20.659 6.561C156.482 12.553 142.528 0 125.641 0c-12.779 0-23.864 7.18-29.493 17.727-6.197-4.394-13.756-6.994-21.934-6.994-15.426 0-28.7 9.223-34.626 22.456a25.018 25.018 0 0 0-9.6-1.918c-13.819 0-25.015 11.203-25.015 25.03 0 2.204.31 4.334.854 6.364C2.277 65.575 0 69.981 0 74.933 0 83.685 7.089 90.78 15.835 90.78a15.66 15.66 0 0 0 4.96-.805c2.698 8.406 10.566 14.509 19.869 14.509 5.159 0 9.884-1.894 13.522-5.001a31.874 31.874 0 0 0 18.507 5.905 31.824 31.824 0 0 0 16.342-4.494 14.332 14.332 0 0 0 8.685 2.921c2.882 0 5.567-.854 7.818-2.315 4.07 6.871 11.53 11.501 20.103 11.501 8.573 0 15.736-4.444 19.856-11.104 3.6 3.317 8.4 5.372 13.67 5.372 6.631 0 12.495-3.206 16.169-8.145 4.726 7.155 12.817 11.872 22.021 11.872 11.431 0 21.13-7.28 24.804-17.456 10.181-2.228 17.814-11.302 17.814-22.159l.025.013Z"
      fill="#fff"
    />
  </svg>
);

export default CloudSvg;
